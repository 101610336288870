import axios from "axios";
import Vue from "vue";
import confetti from "canvas-confetti";
import store from "@/stores/store";

const ICONS = {
    // Generic
    account: "mdi-account-cog",
    activity: "mdi-briefcase",
    add: "mdi-add",
    address: "mdi-map-marker",
    addtoorder: "mdi-cart-outline",
    addtoreceipt: "mdi-dolly",
    addtoshipment: "mdi-truck-fast-outline",
    agent: "mdi-account",
    alert: "mdi-alert-circle-check-outline",
    allocation: "mdi-star-outline",
    api: "mdi-api",
    arrowRight: "mdi-arrow-right",
    back: "mdi-arrow-left",
    down: "mdi-arrow-down",
    blinky: "mdi-wall-sconce-flat-variant",
    cancel: "mdi-cancel",
    catalog: "mdi-book-open-outline",
    check: "mdi-check",
    checkall: "mdi-check-all",
    code: "mdi-barcode",
    csv: "mdi-file-delimited",
    link: "mdi-link",
    default: "mdi-cards-heart-outline",
    delete: "mdi-delete-outline",
    download: "mdi-file-download-outline",
    duplicate: "mdi-content-copy",
    edit: "mdi-pencil",
    email: "mdi-email",
    euro: "mdi-currency-eur",
    eye: "mdi-eye-outline",
    feature: "mdi-cards-playing-heart-multiple-outline",
    home: "mdi-home",
    invitations: "mdi-message-badge-outline",
    instructions: "mdi-message-badge-outline",
    invoice: "mdi-receipt-outline",
    items: "mdi-cube-unfolded",
    login: "mdi-login",
    terms: "mdi-scale-balance",
    magnify: "mdi-magnify",
    missing: "mdi-alert-circle",
    mobilephone: "mdi-cellphone",
    movement: "mdi-plus-minus-variant",
    name: "mdi-card-account-details-outline",
    note: "mdi-note",
    order: "mdi-cart-outline",
    orders: "mdi-cart-outline",
    pdf: "mdi-file-pdf-box",
    phone: "mdi-phone",
    payment: "mdi-cash",
    placed: "mdi-bookshelf",
    plus: "mdi-plus",
    pluscircle: "mdi-plus-circle-outline",
    printer: "mdi-printer",
    product: "mdi-cube-outline",
    products: "mdi-cube-outline",
    receipthandled: "mdi-dolly",
    refund: "mdi-cash-refund",
    release: "mdi-star",
    reload: "mdi-reload",
    save: "mdi-content-save-outline",
    search: "mdi-magnify",
    shipmentprepared: "mdi-truck-fast-outline",
    shipped: "mdi-truck-fast-outline",
    site: "mdi-map-marker",
    status: "mdi-state-machine",
    take: "mdi-star-outline",
    stock: "mdi-counter",
    warehouse: "mdi-warehouse",
    stockid: "mdi-music-accidental-sharp",
    stocklocation: "mdi-map-outline",
    upc: "mdi-ticket-confirmation-outline",
    upload: "mdi-upload",
    packaging: "mdi-inbox-multiple-outline",
    producttype: "mdi-format-list-bulleted-type",

    shipment: "mdi-truck-delivery-outline",
    shipments: "mdi-truck-delivery-outline",
    receipt: "mdi-dolly",
    receipts: "mdi-dolly",
    billing: "mdi-currency-eur",
    price: "mdi-currency-eur",
    settings: "mdi-cog-outline",
    date: "mdi-calendar",
    delay: "mdi-calendar-plus",
    user: "mdi-account",

    // Entities
    operator: "mdi-account-group-outline",
    customer: "mdi-account-box-outline",
    customers: "mdi-account-box-multiple-outline",
    deliverysite: "mdi-domain",
    provider: "mdi-guy-fawkes-mask",
    providers: "mdi-drama-masks",
    manufacturer: "mdi-hammer",
    year: "mdi-calendar",
    siren: "domain",
    vat: "domain",
};

export default {
    triggerFireworks() {
        confetti({
            particleCount: 100,
            spread: 100,
            origin: { y: 0.8 },
            scalar: 1.5,
        });
    },
    getOrderStatusText(order) {
        if (!order.ready) {
            return Vue.prototype.$gettext("Draft");
        }
        if (order.status == "completed") {
            return Vue.prototype.$gettext("Completed");
        }
        if (order.status == "canceled") {
            return Vue.prototype.$gettext("Canceled");
        }
        if (order.status == "waiting") {
            return Vue.prototype.$gettext("To be processed");
        }
        if (order.status == "processing") {
            return Vue.prototype.$gettext("Processing");
        }
        return "";
    },
    getOrderStatusColor(order) {
        if (!order.ready) {
            return "gray";
        }
        if (order.status == "completed") {
            return "success";
        }
        if (order.status == "canceled") {
            return "gray";
        }
        if (order.status == "waiting") {
            return "warning";
        }
        if (order.status == "processing") {
            return "primary";
        }
        return "warning";
    },

    getProviderOrderStatusText(item) {
        if (!item.ready) {
            return Vue.prototype.$gettext("Draft");
        }
        if (item.status == "waiting") {
            if (item.seller_data?.entity) {
                return Vue.prototype.$gettext("Waiting");
            }
            return Vue.prototype.$gettext("To be processed");
        }
        return this.getStatusText(item.status);
    },
    getProviderOrderStatusColor(order) {
        if (!order.ready) {
            return "gray";
        }
        if (order.status == "completed") {
            return "success";
        }
        if (order.status == "canceled") {
            return "gray";
        }
        if (order.status == "waiting") {
            if (order.seller_data?.entity) {
                return "primary";
            }
            return "warning";
        }
        if (order.status == "processing") {
            return "primary";
        }
        return "warning";
    },


    getStatusColor(status) {
        return (
            {
                complete: "success",
                delivered: "success",
                canceled: "gray",
                waiting: "warning",
            }[status] || "grey"
        );
    },
    getPersonaLabel(persona) {
        switch (persona) {
            case "wholesaler":
                return Vue.prototype.$gettext("Wholesaler");
            case "retailer":
                return Vue.prototype.$gettext("Retailer");
            case "manufacturer":
                return Vue.prototype.$gettext("Manufacturer");
            case "agent":
                return Vue.prototype.$gettext("Sales agent");
            case "other":
                return Vue.prototype.$gettext("Other");
        }
        return persona.charAt(0).toUpperCase() + persona.slice(1);
    },
    getPersonasChoices() {
        return ["wholesaler", "retailer", "manufacturer", "agent", "other"].map((persona) => {
            return {
                value: persona,
                text: this.getPersonaLabel(persona),
            };
        });
    },
    toPercent(value) {
        return Math.round(value * 100000) / 1000;
    },
    customerTypeText(type) {
        const CUSTOMER_TYPES_TEXTS = {
            individual: Vue.prototype.$gettext("Individual"),
            company: Vue.prototype.$gettext("Company"),
        };

        return CUSTOMER_TYPES_TEXTS[type];
    },

    icon(code) {
        if (!ICONS[code]) {
            return code;
        }
        return ICONS[code];
    },

    formatNumberToString(value, unit) {
        if (typeof value === "number") {
            if (Number.isInteger(value)) {
                return (
                    value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") +
                    (unit ? " " + unit : "")
                );
            } else {
                return (
                    value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, " ") +
                    (unit ? " " + unit : "")
                );
            }
        } else {
            return value + (unit ? " " + unit : "");
        }
    },

    price(value) {
        if (value === null) {
            return "- €";
        }
        return this.formatNumberToString(value, "€");
    },
    getStatusText(status) {
        const statusTexts = {
            canceled: Vue.prototype.$gettext("Canceled"),
            closed: Vue.prototype.$gettext("Closed"),
            complete: Vue.prototype.$gettext("Complete"),
            completed: Vue.prototype.$gettext("Completed"),
            delivered: Vue.prototype.$gettext("Delivered"),
            exhausted: Vue.prototype.$gettext("Exhausted"),
            handled: Vue.prototype.$gettext("Handled"),
            handling: Vue.prototype.$gettext("Handling"),
            missing: Vue.prototype.$gettext("Missing"),
            new: Vue.prototype.$gettext("New"),
            opened: Vue.prototype.$gettext("Opened"),
            partial: Vue.prototype.$gettext("Partial"),
            pending: Vue.prototype.$gettext("Pending"),
            picked_up: Vue.prototype.$gettext("Picked up"),
            prepared: Vue.prototype.$gettext("Prepared"),
            preparing: Vue.prototype.$gettext("Preparing"),
            processed: Vue.prototype.$gettext("Processed"),
            processing: Vue.prototype.$gettext("Processing"),
            shipping: Vue.prototype.$gettext("Shipping"),
            waiting: Vue.prototype.$gettext("Waiting"),
        };
        return statusTexts[status] || status;
    },
    getInvoiceIdText(item) {
        if (item.status == "draft") {
            return Vue.prototype.$gettext("Draft");
        }
        return `#${item.invoice_id}`;
    },
    getReceiptStatusText(item) {
        if (item.status == "waiting" && !item.ready) {
            return Vue.prototype.$gettext("Draft");
        }
        if (item.status == "waiting" &&
            item.operator == store.getters["session/current_entity_id"]) {
            return Vue.prototype.$gettext("To be processed");
        }

        return this.getStatusText(item.status);
    },
    getReceiptStatusColor(item) {
        if (!item.ready) {
            return "gray";
        }
        if (["partial",].includes(item.status)) {
            return "warning";
        }
        if (["waiting"].includes(item.status)) {
            return "warning";
        }
        if (["handling", "handled"].includes(item.status)) {
            return "primary";
        }

        if (["picked_up", "delivered", "complete", "shipping"].includes(item.status)) {
            return "success";
        }
        return "default";
    },
    getShipmentStatusText(item) {
        if (item.status == "waiting" && !item.ready) {
            return Vue.prototype.$gettext("Draft");
        }
        if (item.status == "waiting") {
            if (item.operator == store.getters["session/current_entity_id"]) {
                return Vue.prototype.$gettext("To be processed");
            }
            return Vue.prototype.$gettext("Waiting");
        }
        return this.getStatusText(item.status);
    },
    getShipmentStatusColor(item) {
        if (!item.ready) {
            return "gray";
        }
        if (["waiting"].includes(item.status)) {
            return "warning";
        }
        if (["preparing", "prepared"].includes(item.status)) {
            return "primary";
        }

        if (["picked_up", "delivered", "complete", "shipping"].includes(item.status)) {
            return "success";
        }
        return "default";
    },
    getProviderInvoiceStatusColor(item) {
        if (item.status == "draft") {
            return "gray";
        }
        if (item.paid) {
            return "success";
        }
        if (this.isInvoiceOverDue(item)) {
            return "warning";
        }
        if (this.isInvoiceDue(item)) {
            return "error";
        }
        return null;
    },
    getProviderInvoiceStatusOutlined(item) {
        return (
            item.paid || (item.status == "validated" && !this.isInvoiceOverDue(item))
        );
    },

    getProviderInvoiceStatusText(providerinvoice) {
        if (!providerinvoice.biller_data?.entity) {
            if (providerinvoice.status == "draft") {
                if (providerinvoice.invoice_file) {
                    return Vue.prototype.$gettext("To validate");
                }
                return Vue.prototype.$gettext("Waiting invoice document");
            }
        }
        if (!providerinvoice.paid) {
            // Checking if due_date is passed
            if (
                providerinvoice.due_date &&
                new Date(providerinvoice.due_date) < new Date()
            ) {
                return Vue.prototype.$gettext("Due");
            }
            return Vue.prototype.$gettext("Waiting");
        }
        return Vue.prototype.$gettext("Paid");
    },
    isInvoiceOverDue(invoice) {
        return Vue.prototype
            .$moment(invoice.due_date)
            .isBefore(Vue.prototype.$moment(), "day");
    },
    isInvoiceDue(invoice) {
        return Vue.prototype
            .$moment(invoice.due_date)
            .isSameOrBefore(Vue.prototype.$moment(), "day");
    },
    getCustomerInvoiceStatusColor(item) {
        if (item.status == "draft") {
            return "gray";
        }
        if (item.paid) {
            return "success";
        }
        if (this.isInvoiceOverDue(item)) {
            return "error";
        }
        if (this.isInvoiceDue(item)) {
            return "warning";
        }
        if (!item.sent) {
            return "warning";
        }
        return null;
    },
    getCustomerInvoiceStatusText(item) {
        if (item.status == "draft") {
            return Vue.prototype.$gettext("Draft");
        }
        if (item.paid) {
            return item.credit
                ? Vue.prototype.$gettext("Cashed")
                : Vue.prototype.$gettext("Paid");
        }
        if (this.isInvoiceOverDue(item)) {
            return Vue.prototype.$gettext("Payment overdue");
        }
        if (this.isInvoiceDue(item)) {
            return Vue.prototype.$gettext("Payment due");
        }
        if (!item.sent) {
            return Vue.prototype.$gettext("To be sent");
        }
        return Vue.prototype.$gettext("Pending invoice");
    },
    getCustomerInvoiceStatusOutlined(item) {
        if (item.paid) {
            return true;
        }
        if (item.status == "validated") {
            if (!item.sent) {
                return false;
            }
            if (!this.isInvoiceDue(item)) {
                return true;
            }
        }
        return false;
    },

    getOrderInvoicesStatusText(item) {
        if (!item.readonly) {
            return null;
        }
        if (item.status == 'canceled') {
            return Vue.prototype.$gettext("Order canceled");
        }
        if (!item.invoices_ready) {
            if (!item.invoices_count) {
                return Vue.prototype.$gettext("Missing invoices");
            }
            return Vue.prototype.$gettext("Waiting validation");
        }
        if (!item.invoices_sent) {
            return Vue.prototype.$gettext("To send");
        }
        if (!item.paid) {
            return Vue.prototype.$gettext("Waiting payment");
        }
        if (item.paid) {
            return Vue.prototype.$gettext("Paid");
        }
        return item.status;
    },
    getOrderInvoiceStatusOutlined(order) {
        if (!order.readonly) {
            return false;
        }
        if (order.status == 'canceled') {
            return true;
        }
        if (!order.invoices_ready) {
            if (!order.invoices_count) {
                return false;
            }
            return false;
        }
        if (!order.invoices_sent) {
            return false;
        }
        if (!order.paid) {
            return true;
        }
        return true;
    },

    getOrderInvoicesStatusColor(order) {
        if (!order.readonly) {
            return "gray";
        }
        if (order.status == 'canceled') {
            return "gray";
        }
        if (!order.invoices_ready) {
            if (!order.invoices_count) {
                return "error";
            }
            return "warning";
        }
        if (!order.invoices_sent) {
            return "warning";
        }
        if (!order.paid) {
            return "primary";
        }
        if (order.paid) {
            return "success";
        }
        return order.status;
    },

    resultToSwalContent(view, result) {
        let non_field_errors = null;
        let details = null;

        if (result?.details) {
            details = JSON.parse(JSON.stringify(result.details));
        }
        if (details && details.non_field_errors) {
            non_field_errors = details.non_field_errors;
            delete details.non_field_errors;
        } else if (!details) {
            return (
                '<div class="sweeterrors">' +
                view.$gettext("Unknown error occured") +
                "</div>"
            );
        }
        let content = '<div class="sweeterrors">';
        if (details.detail) {
            content += `<ul><li>${details.detail}</li></ul>`;
        } else if ([400, 412].indexOf(result.code) != -1) {
            content += "<ul>";
            if (details && Object.keys(details).length) {
                content += `<li>${view.$gettext(
                    "Fields errors, check form errors and try again"
                )}</li>`;
            } else if (non_field_errors) {
                (non_field_errors || []).forEach((error) => {
                    content += `<li>${error}</li>`;
                });
            } else {
                content += `<li>${view.$gettext(
                    "Server reported bad form values, please check values and try again"
                )}</li>`;
            }
            content += "</ul>";
        } else if ([403].indexOf(result.code) != -1) {
            if (non_field_errors) {
                content += `${view.$gettext("This action cannot be performed.")}<br/>`;
                content += "<ul>";
                (non_field_errors || []).forEach((error) => {
                    content += `<li>${error}</li>`;
                });
                content += "</ul>";
            } else {
                content += result.details || `${view.$gettext("Permission denied")}`;
            }
        } else {
            content += "<ul>";
            content += `<li>${view.$gettext(
                "An unexpected error happened, please contact us for support"
            )}</li>`;
            content += "</ul>";
        }

        content += "</div>";
        return content;
    },

    resultToSwalContentForField(view, result, field) {
        let non_field_errors = null;
        let details = null;
        if (result.details) {
            details = JSON.parse(JSON.stringify(result.details));
        }
        if (details && details.non_field_errors) {
            non_field_errors = details.non_field_errors;
            delete details.non_field_errors;
        }

        let content = '<div class="sweeterrors"><ul>';
        if ([400, 412].indexOf(result.code) != -1) {
            if (details && Object.keys(details).length) {
                content += `<li>${details[field]}</li>`;
            } else if (non_field_errors) {
                (non_field_errors || []).forEach((error) => {
                    content += `<li>${error}</li>`;
                });
            } else {
                content += `<li>${view.$gettext(
                    "Server reported bad form values, please check values and try again"
                )}</li>`;
            }
        } else if (details.detail) {
            content += `<li>${details.detail}</li>`;
        } else {
            content += `<li>${view.$gettext(
                "An unexpected error happened, please contact us for support"
            )}</li>`;
        }

        content += "</ul></div>";
        return content;
    },
    humanDate(date) {
        // Full textual date
        return Vue.prototype.$moment(date).format("dddd Do MMMM YYYY, hh:mm");
    },
    isAddressComplete(address) {
        return !!(
            address?.email &&
            address?.city &&
            address?.line1 &&
            address?.postal_code
        );
    },
    isEntityInformationComplete(entity) {
        return !!(this.isAddressComplete(entity.billing_address) && entity.siren);
    },
    isCustomerInformationComplete(customer) {
        return !!(this.isAddressComplete(customer.billing_address) && customer.siren);
    },
    isBillingAccountComplete(billingaccount) {
        if (!billingaccount) {
            return false;
        }
        return billingaccount.siren && this.isAddressComplete(billingaccount.address);
    },
    async lookup(object, queryparams) {
        try {
            const response = await axios.get(`/api/${object}s/`, {
                params: queryparams,
            });
            if (response.data.results.length) {
                return response.data.results[0].id;
            }
        } catch (xhr_error) {
            return null;
        }
    },
};
